









































import { defineComponent, ref } from "@vue/composition-api";
import AuthService from "@/serives/AuthService";
import TreeSelect from "@/components/TreeSelect.vue";
import BackendService from "@/serives/BackendService";
import ApiService from "@/serives/ApiService";
import LocalStorageService from "@/serives/LocalStorageService";
import ProductDetail from "@/types/ProductDetail";

export default defineComponent({
  name: "Manual",
  components: {
    TreeSelect,
  },
  setup(props, context) {
    const taxonomy = ref<Array<string>>([]);
    const loading = ref<boolean>(false);
    const loadingExtract = ref<boolean>(false);
    const title = ref<string | null>("");
    const url = ref<string | null>("");
    const description = ref<Array<string>>([""]);
    const siteTaxonomy = ref<string | null>("");
    const router = context.root.$router;

    const backendService = new BackendService(
      new ApiService(new LocalStorageService()),
      new LocalStorageService()
    );

    const onLabelSelect = (selected: Array<string>): void => {
      taxonomy.value = selected;
    };

    const onNext = async (): Promise<void> => {
      try {
        loading.value = true;
        if (!taxonomy.value || taxonomy.value.length === 0) {
          alert("Please select a label :)");
        } else {
          const siteTaxonomyArray: Array<string> = siteTaxonomy.value
            ? siteTaxonomy.value.trim().split(",")
            : [];
          for (let i = 0; i < siteTaxonomyArray.length; i++) {
            siteTaxonomyArray[i] = siteTaxonomyArray[i].trim();
          }
          backendService.submit(
            url.value,
            description.value,
            title.value,
            taxonomy.value,
            siteTaxonomyArray
          );
          reset();
        }
      } catch (error) {
        console.error(error);
        alert("something went wrong");
      } finally {
        loading.value = false;
      }
    };

    const onLogout = () => {
      AuthService.logout();
      router.push("Login");
    };

    const onUrl = async (event: InputEvent) => {
      url.value = (event.target as HTMLInputElement).value;
      if (validURL(url.value)) {
        try {
          loadingExtract.value = true;
          const productDetails: Array<ProductDetail> =
            await backendService.getExtract(url.value);
          if (productDetails && productDetails.length > 0) {
            title.value = productDetails[0].Title;
            description.value = productDetails[0].Description
              ? productDetails[0].Description
              : [""];
          }
        } catch (error) {
          console.error(error);
        } finally {
          loadingExtract.value = false;
        }
      }
    };

    const validURL = (str: string) => {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    };

    const onTitle = (event: InputEvent) => {
      title.value = (event.target as HTMLInputElement).value;
    };

    const onDescription = (event: InputEvent, index: number) => {
      description.value[index] = (event.target as HTMLInputElement).value;
    };

    const onSiteTaxonomy = (event: InputEvent) => {
      siteTaxonomy.value = (event.target as HTMLInputElement).value;
    };

    const onSkip = () => {
      reset();
    };

    const reset = () => {
      title.value = "";
      description.value = [""];
      siteTaxonomy.value = "";
      taxonomy.value = [];
      url.value = "";
    };

    const onAddDescription = () => {
      description.value.push("");
    };

    return {
      url,
      taxonomy,
      title,
      description,
      siteTaxonomy,
      loading,
      loadingExtract,
      onLabelSelect,
      onNext,
      onLogout,
      onTitle,
      onDescription,
      onSiteTaxonomy,
      onUrl,
      onSkip,
      onAddDescription,
    };
  },
});
