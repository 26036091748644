import { render, staticRenderFns } from "./NewTaxonomy.vue?vue&type=template&id=12ebe418&scoped=true&"
import script from "./NewTaxonomy.vue?vue&type=script&lang=ts&"
export * from "./NewTaxonomy.vue?vue&type=script&lang=ts&"
import style0 from "./NewTaxonomy.vue?vue&type=style&index=0&id=12ebe418&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ebe418",
  null
  
)

export default component.exports