












































































import { defineComponent, onMounted, ref } from "@vue/composition-api";
import SequenceService from "@/serives/SequenceServiceNewTex";
import AuthService from "@/serives/AuthService";
import NewTaxonomyProduct from "@/types/ProductNewTex";
import TreeSelect from "@/components/TreeSelect.vue";
import Task from "@/types/Task";
import SequenceItemNewTex from "@/types/SequenceItemNewTex";

export default defineComponent({
  name: "NewTaxonomy",
  components: {
    TreeSelect,
  },
  setup(props, context) {
    const product = ref<NewTaxonomyProduct | undefined>();
    const taxonomy = ref<Array<string>>();
    const loading = ref<boolean>(false);
    const router = context.root.$router;
    const task = ref<Task | undefined>();
    const isOutOfTaxonomy = ref<boolean>(false);

    onMounted(async (): Promise<void> => {
      getNext();
    });

    const onLabelSelect = (selected: Array<string>): void => {
      taxonomy.value = selected;
    };

    const onNext = async (): Promise<void> => {
      try {
        loading.value = true;
        if (
          (!taxonomy.value || taxonomy.value.length === 0) &&
          (!product.value || !product.value.OutOfTaxonomy)
        ) {
          alert("Please select a lebel :)");
        } else {
          if (product.value && task.value) {
            const newTask = { ...task.value };
            newTask.Taxonomy = taxonomy.value || [];
            newTask.Failed = false;
            newTask.Id = task.value.Id;
            const dataToUpdate = {
              Product: product.value,
              Task: newTask,
            } as SequenceItemNewTex;
            await SequenceService.update(dataToUpdate);
          }
          getNext();
        }
      } catch (error) {
        console.error(error);
        alert("something went wrong");
      } finally {
        loading.value = false;
      }
    };

    const onSkip = async (): Promise<void> => {
      if (product.value && task.value) {
        taxonomy.value = [];
        const newTask = { ...task.value };
        newTask.Taxonomy = taxonomy.value;
        newTask.Failed = true;
        newTask.Id = task.value.Id;
        const dataToUpdate = {
          Product: product.value,
          Task: newTask,
        } as SequenceItemNewTex;
        await SequenceService.update(dataToUpdate);
      }
      getNext();
    };

    const onPrevious = (): void => {
      const data = SequenceService.previous();
      product.value = data.Product;
      isOutOfTaxonomy.value = false;
      if (data.Task) taxonomy.value = data.Task.Taxonomy;
      task.value = data.Task;
    };

    const getNext = async (): Promise<void> => {
      const data = await SequenceService.next();
      product.value = data.Product;
      task.value = data.Task;
      isOutOfTaxonomy.value = false;
      taxonomy.value =
        data.Task && data.Task.Taxonomy ? data.Task.Taxonomy : [];
    };

    const onLogout = () => {
      AuthService.logout();
      router.push("Login");
    };

    const handleTitleChange = (event: InputEvent) => {
      if (product.value)
        product.value.Title = (event.target as HTMLInputElement).value;
    };

    const onDescription = (event: InputEvent, index: number) => {
      if (product.value)
        product.value.ProductDescription[index] = (
          event.target as HTMLInputElement
        ).value;
    };

    const handleNonAdult = (event: InputEvent) => {
      if (product.value) {
        const t = event.target as HTMLInputElement;
        product.value.IsNonAdult = t.checked;
      }
    };

    const onAddDescription = () => {
      if (product.value) product.value.ProductDescription.push("");
    };

    const handleIsOutOfTaxonomy = (event: InputEvent) => {
      const t = event.target as HTMLInputElement;
      isOutOfTaxonomy.value = t.checked;
      if (!isOutOfTaxonomy.value && product.value) {
        product.value.OutOfTaxonomy = undefined;
      }
    };

    const handleOutOfTaxonomy = (event: InputEvent) => {
      if (product.value) {
        const t = event.target as HTMLInputElement;
        product.value.OutOfTaxonomy = t.value;
      }
    };

    return {
      product,
      taxonomy,
      loading,
      onLabelSelect,
      onNext,
      onSkip,
      onPrevious,
      onLogout,
      handleTitleChange,
      onDescription,
      onAddDescription,
      handleNonAdult,
      handleOutOfTaxonomy,
      handleIsOutOfTaxonomy,
      isOutOfTaxonomy,
    };
  },
});
