












import { defineComponent, ref } from "@vue/composition-api";
import LocalStorageService from "@/serives/LocalStorageService";
import StorageKey from "@/types/StorageKey";

export default defineComponent({
  name: "Login",
  setup(props, context) {
    const router = context.root.$router;
    const username = ref<string>("");
    const secret = ref<string>("");
    const localStorageService = new LocalStorageService();

    const onUsernameInput = (event: { target: HTMLInputElement }): void => {
      username.value = event.target.value;
    };

    const onSecretInput = (event: { target: HTMLInputElement }): void => {
      secret.value = event.target.value;
    };

    const onSubmit = (): void => {
      localStorageService.set(StorageKey.USERNAME, username.value);
      localStorageService.set(StorageKey.KEY, secret.value);
      router.push("./");
    };

    return {
      username,
      secret,
      onUsernameInput,
      onSecretInput,
      onSubmit,
    };
  },
});
