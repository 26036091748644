















































































import { defineComponent, ref } from "@vue/composition-api";
import AuthService from "@/serives/AuthService";
import TreeSelect from "@/components/TreeSelect.vue";
import SequenceMaterialsService from "@/serives/SequenceMaterialsService";
import ApiService from "@/serives/ApiService";
import LocalStorageService from "@/serives/LocalStorageService";
import BackendService from "@/serives/BackendService";
import ProductDetail from "@/types/ProductDetail";
import MaterialComponent from "@/components/Component.vue";
import MaterialComponentType from "@/types/MaterialComponentType";
import SequenceItemMaterials from "@/types/SequenceItemMaterials";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  name: "Materials",
  components: {
    TreeSelect,
    MaterialComponent,
  },
  setup(props, context) {
    const loading = ref<boolean>(false);
    const loadingExtract = ref<boolean>(false);
    const title = ref<string | null>("");
    const url = ref<string | null>("");
    const description = ref<Array<string>>([""]);
    const router = context.root.$router;
    const entireProduct = ref<boolean>();
    const backendService = new BackendService(
      new ApiService(new LocalStorageService()),
      new LocalStorageService()
    );
    const components = ref<Array<MaterialComponentType>>([
      {
        id: uuidv4(),
        name: "",
        materials: [
          {
            name: "",
            value: 100,
            unit: "fraction",
          },
        ],
      },
    ]);

    const onNext = async (ComponentUnclear = false): Promise<void> => {
      try {
        loading.value = true;
        const result: SequenceItemMaterials | undefined =
          await SequenceMaterialsService.nextAndSave({
            Title: title.value,
            Url: url.value,
            ProductDescription: description.value,
            Components: components.value,
            ComponentUnclear,
            IsEntireProduct: entireProduct.value,
          } as SequenceItemMaterials);
        if (!result) reset();
        else fillTheFields(result);
      } catch (error) {
        console.error(error);
        alert("something went wrong");
      } finally {
        loading.value = false;
      }
    };

    const onPrevious = () => {
      const result: SequenceItemMaterials = SequenceMaterialsService.previous();
      fillTheFields(result);
    };

    const fillTheFields = (result: SequenceItemMaterials) => {
      url.value = result.Url;
      description.value = result.ProductDescription;
      title.value = result.Title;
      components.value = result.Components;
      entireProduct.value = result.IsEntireProduct;
    };

    const onCompositionUnclear = async () => {
      await onNext(true);
    };

    const onLogout = () => {
      AuthService.logout();
      router.push("Login");
    };

    const onUrl = async (event: InputEvent) => {
      url.value = (event.target as HTMLInputElement).value;
      if (validURL(url.value)) {
        try {
          loadingExtract.value = true;
          const productDetails: Array<ProductDetail> =
            await backendService.getExtract(url.value);
          if (productDetails && productDetails.length > 0)
            title.value = productDetails[0].Title;
        } catch (error) {
          console.error(error);
        } finally {
          loadingExtract.value = false;
        }
      }
    };

    const validURL = (str: string) => {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    };

    const onTitle = (event: InputEvent) => {
      title.value = (event.target as HTMLInputElement).value;
    };

    const onDescription = (event: InputEvent, index: number) => {
      description.value[index] = (event.target as HTMLInputElement).value;
    };

    const onSkip = () => {
      reset();
    };

    const reset = () => {
      title.value = "";
      description.value = [""];
      url.value = "";
      entireProduct.value = false;
      components.value = [
        {
          id: uuidv4(),
          name: "",
          materials: [
            {
              name: "",
              value: 100,
              unit: "fraction",
            },
          ],
        },
      ];
    };

    const onComponentDelete = (index: number): void => {
      components.value.splice(index, 1);
    };

    const onAddComponent = (): void => {
      components.value.push({
        id: uuidv4(),
        name: "",
        materials: [
          {
            name: "",
            value: 100,
            unit: "fraction",
          },
        ],
      });
    };

    const onComponentChange = (
      value: MaterialComponentType,
      index: number
    ): void => {
      components.value[index] = value;
    };

    const onEntireProduct = (value: boolean) => {
      entireProduct.value = value;
    };

    const onAddDescription = () => {
      description.value.push("");
    };

    const onSaveAndNext = async () => {
      await onNext(false);
    };

    return {
      url,
      title,
      description,
      loading,
      loadingExtract,
      components,
      entireProduct,
      onSaveAndNext,
      onPrevious,
      onCompositionUnclear,
      onLogout,
      onTitle,
      onDescription,
      onUrl,
      onSkip,
      onComponentDelete,
      onAddComponent,
      onComponentChange,
      onEntireProduct,
      onAddDescription,
    };
  },
});
