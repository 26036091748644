import Category from "./Category";

export default [
  {
    "Name": "Fashion",
    "Children": [
      {
        "Name": "Bottoms",
        "Children": [
          {
            "Name": "Pants",
            "Children": [
              {
                "Name": "Jeans Pants",
                "Children": [
                  {
                    "Name": "Loose Fit Jeans",
                    "Children": []
                  },
                  {
                    "Name": "Slim Jeans",
                    "Children": []
                  },
                  {
                    "Name": "Skinny Jeans",
                    "Children": [
                      {
                        "Name": "Stretchable Skinny Jeans",
                        "Children": []
                      }
                    ]
                  },
                  {
                    "Name": "Regular Jeans",
                    "Children": [
                      {
                        "Name": "Stretchable Regular Jeans",
                        "Children": []
                      }
                    ]
                  },
                  {
                    "Name": "Relaxed Jeans",
                    "Children": [
                      {
                        "Name": "High Waist Relaxed Jeans",
                        "Children": []
                      }
                    ]
                  },
                  {
                    "Name": "Stretchable Jeans",
                    "Children": []
                  },
                  {
                    "Name": "High Waist Jeans",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Corduroy Pants",
                "Children": [
                  {
                    "Name": "Slim Fit Corduroy Pants",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Culottes Pants",
                "Children": []
              },
              {
                "Name": "Flared Pants",
                "Children": []
              },
              {
                "Name": "Leather Pants",
                "Children": []
              },
              {
                "Name": "Track Pants",
                "Children": []
              },
              {
                "Name": "Knitted Pants",
                "Children": []
              },
              {
                "Name": "Cigarette Pants",
                "Children": []
              },
              {
                "Name": "Balloon Pants",
                "Children": []
              },
              {
                "Name": "High Waist Pants",
                "Children": []
              },
              {
                "Name": "Regular Fit Pants",
                "Children": []
              },
              {
                "Name": "Skinny Fit Pants",
                "Children": []
              },
              {
                "Name": "Slim Fit Pants",
                "Children": []
              },
              {
                "Name": "Cropped Pants",
                "Children": [
                  {
                    "Name": "Skinny Fit Cropped Pants",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Cargo Pants",
                "Children": [
                  {
                    "Name": "Skinny Fit Cargo Pants",
                    "Children": []
                  },
                  {
                    "Name": "Relaxed Fit Cargo Pants",
                    "Children": []
                  },
                  {
                    "Name": "Slim Fit Cargo Pants",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Sweatpants",
                "Children": [
                  {
                    "Name": "Regular Sweatpants",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Chinos Pants",
                "Children": [
                  {
                    "Name": "Regular Fit Chinos",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Suit Pants",
                "Children": [
                  {
                    "Name": "Cropped Suit Pants",
                    "Children": []
                  },
                  {
                    "Name": "Skinny Fit Suit Pants",
                    "Children": []
                  },
                  {
                    "Name": "Straight Fit Suit Pants",
                    "Children": []
                  },
                  {
                    "Name": "Regular Fit Suit Pants",
                    "Children": []
                  },
                  {
                    "Name": "Slim Fit Suit Pants",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Sports Pants",
                "Children": [
                  {
                    "Name": "Sports Joggers",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Joggers",
                "Children": [
                  {
                    "Name": "Regular Fit Joggers",
                    "Children": []
                  },
                  {
                    "Name": "Cropped Joggers",
                    "Children": []
                  },
                  {
                    "Name": "Loose Fit Joggers",
                    "Children": [
                      {
                        "Name": "High Waist Loose Fit Joggers",
                        "Children": []
                      }
                    ]
                  },
                  {
                    "Name": "High Waist Joggers",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Tights",
            "Children": [
              {
                "Name": "Sports Tights",
                "Children": [
                  {
                    "Name": "High Waist Tights",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Fishnet Tights",
                "Children": []
              },
              {
                "Name": "Leggings",
                "Children": [
                  {
                    "Name": "Maternity Leggings",
                    "Children": []
                  },
                  {
                    "Name": "Crop Leggings",
                    "Children": []
                  },
                  {
                    "Name": "Leather Leggings",
                    "Children": []
                  },
                  {
                    "Name": "High Waist Leggings",
                    "Children": []
                  },
                  {
                    "Name": "Flared Leggings",
                    "Children": [
                      {
                        "Name": "High Waist Flared Leggings",
                        "Children": []
                      }
                    ]
                  },
                  {
                    "Name": "Jeggings",
                    "Children": []
                  },
                  {
                    "Name": "High Waist Jeggings",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Nightwear Bottoms",
            "Children": [
              {
                "Name": "Pyjama Bottoms",
                "Children": []
              }
            ]
          },
          {
            "Name": "Shorts",
            "Children": [
              {
                "Name": "Leggiing Shorts",
                "Children": []
              },
              {
                "Name": "Maternity Shorts",
                "Children": []
              },
              {
                "Name": "Regular Fit Shorts",
                "Children": []
              },
              {
                "Name": "Slim Fit Shorts",
                "Children": []
              },
              {
                "Name": "Relaxed Fit Shorts",
                "Children": []
              },
              {
                "Name": "Straight Fit Shorts",
                "Children": []
              },
              {
                "Name": "High Waist Shorts",
                "Children": []
              },
              {
                "Name": "Short Bottom Swimwear",
                "Children": [
                  {
                    "Name": "Swimming Briefs",
                    "Children": []
                  },
                  {
                    "Name": "Swimming Shorts",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Sports Shorts",
                "Children": [
                  {
                    "Name": "Running Shorts",
                    "Children": []
                  },
                  {
                    "Name": "Mesh Shorts",
                    "Children": []
                  },
                  {
                    "Name": "Cycling Shorts",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Chino Shorts",
                "Children": []
              },
              {
                "Name": "Knee Length Shorts",
                "Children": []
              },
              {
                "Name": "Cargo Shorts",
                "Children": []
              },
              {
                "Name": "Biker Shorts",
                "Children": []
              },
              {
                "Name": "Leather Shorts",
                "Children": []
              },
              {
                "Name": "Skirt Shorts",
                "Children": []
              }
            ]
          },
          {
            "Name": "Bikini Bottom",
            "Children": [
              {
                "Name": "Sports Bikini Bottom",
                "Children": []
              },
              {
                "Name": "Thong Bikini Bottom",
                "Children": []
              }
            ]
          },
          {
            "Name": "Skirt",
            "Children": [
              {
                "Name": "Pencil Skirt",
                "Children": [
                  {
                    "Name": "Short Pencil Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Knee Length Pencil Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Calf Length Pencil Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Ankle length Pencil Skirt",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Pleated Skirt",
                "Children": [
                  {
                    "Name": "Short Pleated Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Knee Length Pleated Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Calf Length Pleated Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Ankle Length Pleated Skirt",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "A-Line Skirt",
                "Children": [
                  {
                    "Name": "Short A-Line Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Knee Length A-Line Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Calf Length A-Line Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Ankle Length A-Line Skirt",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Short Skirt",
                "Children": []
              },
              {
                "Name": "Knee Length Skirt",
                "Children": []
              },
              {
                "Name": "Calf Length Skirt",
                "Children": []
              },
              {
                "Name": "Ankle Length Skirt",
                "Children": []
              },
              {
                "Name": "Bell Shaped Skirt",
                "Children": []
              },
              {
                "Name": "Balloon Skirt",
                "Children": []
              },
              {
                "Name": "High Waist Skirt",
                "Children": []
              },
              {
                "Name": "Sports Skirt",
                "Children": [
                  {
                    "Name": "Tennis Skirt",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Draped Skirt",
                "Children": [
                  {
                    "Name": "Short Draped Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Knee Length Draped Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Calf Length Draped Skirt",
                    "Children": []
                  },
                  {
                    "Name": "Ankle Length Draped Skirt",
                    "Children": []
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "Name": "Top",
        "Children": [
          {
            "Name": "Jacket",
            "Children": [
              {
                "Name": "Puffer Jacket",
                "Children": [
                  {
                    "Name": "Long Puffer jacket",
                    "Children": []
                  },
                  {
                    "Name": "Boxy Puffer Jacket",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Down Jacket",
                "Children": [
                  {
                    "Name": "Knee Length Down Jacket",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Shirt Jacket",
                "Children": [
                  {
                    "Name": "Oversized Shirt Jacket",
                    "Children": []
                  },
                  {
                    "Name": "Relaxed Fit Shirt Jacket",
                    "Children": []
                  },
                  {
                    "Name": "Jersey Shirt Jacket",
                    "Children": []
                  },
                  {
                    "Name": "Long Shirt Jacket",
                    "Children": []
                  },
                  {
                    "Name": "Sleeveless Shirt Jacket",
                    "Children": []
                  },
                  {
                    "Name": "Regular Fit Shirt Jacket",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Bomber Jacket",
                "Children": [
                  {
                    "Name": "Oversized Bomber Jacket",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Parka",
                "Children": [
                  {
                    "Name": "Loose Fit Parka",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Retro Jacket",
                "Children": [
                  {
                    "Name": "Cropped Retro Jacket",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Cropped Jacket",
                "Children": []
              },
              {
                "Name": "Oversized Jacket",
                "Children": []
              },
              {
                "Name": "Sports Jacket",
                "Children": []
              },
              {
                "Name": "Hooded Jacket",
                "Children": []
              },
              {
                "Name": "Shell Jacket",
                "Children": []
              },
              {
                "Name": "Double Breasted Jacket",
                "Children": []
              },
              {
                "Name": "Single Breasted Jacket",
                "Children": []
              },
              {
                "Name": "Skinny Fit Jacket",
                "Children": []
              },
              {
                "Name": "Regular Fit Jacket",
                "Children": []
              },
              {
                "Name": "Boxy Style Jacket",
                "Children": []
              },
              {
                "Name": "Relaxed Fit Jacket",
                "Children": []
              },
              {
                "Name": "Slim Fit Jacket",
                "Children": []
              },
              {
                "Name": "Cape Jacket",
                "Children": []
              },
              {
                "Name": "Popover Jacket",
                "Children": []
              },
              {
                "Name": "Baseball Jacket",
                "Children": []
              },
              {
                "Name": "Biker Jacket",
                "Children": []
              },
              {
                "Name": "Sleeveless Jacket",
                "Children": []
              },
              {
                "Name": "Track Jacket",
                "Children": [
                  {
                    "Name": "Regular Fit Track Jacket",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Gilet",
                "Children": [
                  {
                    "Name": "Cropped Gilet",
                    "Children": []
                  },
                  {
                    "Name": "Oversized Gilet",
                    "Children": []
                  },
                  {
                    "Name": "Hooded Gilet",
                    "Children": [
                      {
                        "Name": "Hooded Puffer Gilet",
                        "Children": []
                      }
                    ]
                  },
                  {
                    "Name": "Varsity Gilet",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Varsity Jacket",
                "Children": [
                  {
                    "Name": "Oversized Varsity Jacket",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Tuxedo jacket",
                "Children": [
                  {
                    "Name": "Slim Fit Tuxedo Jacket",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Coat",
            "Children": [
              {
                "Name": "Calf Length Coat",
                "Children": []
              },
              {
                "Name": "Single Breasted Coat",
                "Children": []
              },
              {
                "Name": "Double Breasted Coat",
                "Children": []
              },
              {
                "Name": "Down Coat",
                "Children": []
              },
              {
                "Name": "Oversized Coat",
                "Children": []
              },
              {
                "Name": "Long Coat",
                "Children": []
              },
              {
                "Name": "Knee Length Coat",
                "Children": []
              },
              {
                "Name": "Collared Coat",
                "Children": [
                  {
                    "Name": "High Collared Coat",
                    "Children": [
                      {
                        "Name": "Single Breasted High Collared Coat",
                        "Children": []
                      }
                    ]
                  }
                ]
              },
              {
                "Name": "Trenchcoat",
                "Children": [
                  {
                    "Name": "Oversized Trenchcoat",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Waistcoat",
            "Children": [
              {
                "Name": "Slim Fit Waistcoat",
                "Children": []
              },
              {
                "Name": "Biker Waistcoat",
                "Children": []
              }
            ]
          },
          {
            "Name": "Sports Top",
            "Children": [
              {
                "Name": "Regular Fit Sports Top",
                "Children": []
              },
              {
                "Name": "Long Sleeved Sports Top",
                "Children": []
              },
              {
                "Name": "Relaxed Fit Sports Top",
                "Children": []
              }
            ]
          },
          {
            "Name": "Hoodie",
            "Children": [
              {
                "Name": "Cropped Hoodie",
                "Children": []
              },
              {
                "Name": "Sports Hoodie",
                "Children": [
                  {
                    "Name": "Cropped Sports Hoodie",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Relaxed Fit Hoodie",
                "Children": []
              },
              {
                "Name": "Regular Fit Hoodie",
                "Children": []
              },
              {
                "Name": "Oversized Hoodie",
                "Children": []
              }
            ]
          },
          {
            "Name": "Sweatshirt",
            "Children": [
              {
                "Name": "Relaxed Fit Sweatshirt",
                "Children": []
              },
              {
                "Name": "Regular Fit Sweatshirt",
                "Children": []
              },
              {
                "Name": "Oversized Sweatshirt",
                "Children": []
              }
            ]
          },
          {
            "Name": "Blazer",
            "Children": [
              {
                "Name": "Single Breasted Blazer",
                "Children": []
              },
              {
                "Name": "Double Breasted Blazer",
                "Children": []
              },
              {
                "Name": "Cropped Blazer",
                "Children": []
              },
              {
                "Name": "Slim Fit Blazer",
                "Children": []
              }
            ]
          },
          {
            "Name": "Unitard",
            "Children": [
              {
                "Name": "Sleeveless Unitard",
                "Children": []
              }
            ]
          },
          {
            "Name": "Jumpsuit",
            "Children": [
              {
                "Name": "Hooded Jumpsuit",
                "Children": []
              },
              {
                "Name": "Halterneck Jumpsuit",
                "Children": []
              }
            ]
          },
          {
            "Name": "Playsuit",
            "Children": [
              {
                "Name": "Polo Neck Playsuit",
                "Children": []
              },
              {
                "Name": "Drawstring Playsuit",
                "Children": []
              }
            ]
          },
          {
            "Name": "Tunic",
            "Children": [
              {
                "Name": "Long Sleeved Tunic",
                "Children": []
              },
              {
                "Name": "Polo Neck Tunic",
                "Children": []
              }
            ]
          },
          {
            "Name": "Crop Top",
            "Children": []
          },
          {
            "Name": "Boiler Suit",
            "Children": []
          },
          {
            "Name": "Bikini Top",
            "Children": [
              {
                "Name": "Sports Bikini Top",
                "Children": []
              },
              {
                "Name": "Push-up Bikini Top",
                "Children": []
              },
              {
                "Name": "Padded Bikini Top",
                "Children": []
              }
            ]
          },
          {
            "Name": "Nightwear Top",
            "Children": [
              {
                "Name": "Nightshirt",
                "Children": []
              },
              {
                "Name": "Pyjama Shirt",
                "Children": []
              }
            ]
          },
          {
            "Name": "Jumper",
            "Children": [
              {
                "Name": "Loose Fit Jumper",
                "Children": []
              },
              {
                "Name": "Round Neck Jumper",
                "Children": []
              },
              {
                "Name": "Slim Fit Jumper",
                "Children": []
              },
              {
                "Name": "Cropped Jumper",
                "Children": []
              },
              {
                "Name": "Sleeveless Jumper",
                "Children": []
              },
              {
                "Name": "Off Shoulder Jumper",
                "Children": []
              },
              {
                "Name": "Muscle Fit Jumper",
                "Children": []
              },
              {
                "Name": "Relaxed Fit Jumper",
                "Children": []
              },
              {
                "Name": "Boxy Style Jumper",
                "Children": []
              },
              {
                "Name": "Oversized Jumper",
                "Children": []
              },
              {
                "Name": "V-Neck Jumper",
                "Children": []
              },
              {
                "Name": "Polo Neck Jumper",
                "Children": [
                  {
                    "Name": "Cropped Polo Neck Jumper",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Collared Jumper",
                "Children": [
                  {
                    "Name": "Stand Up Collar Jumper",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Regular Fit Jumper",
                "Children": [
                  {
                    "Name": "Regular Fit Polo Neck Jumper",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Sweater",
            "Children": [
              {
                "Name": "Sweater Vest",
                "Children": [
                  {
                    "Name": "Regular Fit Sweater Vest",
                    "Children": []
                  },
                  {
                    "Name": "Oversized Sweater Vest",
                    "Children": []
                  },
                  {
                    "Name": "Polo Neck Sweater Vest",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Cardigan",
            "Children": [
              {
                "Name": "Cropped Cardigan",
                "Children": []
              },
              {
                "Name": "Double Breasted Cardigan",
                "Children": []
              },
              {
                "Name": "Tie Belt Cardigan",
                "Children": []
              },
              {
                "Name": "Long Cardigan",
                "Children": []
              },
              {
                "Name": "Short Cardigan",
                "Children": []
              },
              {
                "Name": "Shawl Collar Cardigan",
                "Children": []
              },
              {
                "Name": "Boxy Style Cardigan",
                "Children": []
              },
              {
                "Name": "Hooded Cardigan",
                "Children": [
                  {
                    "Name": "Long Hooded Cardigan",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Cape",
            "Children": []
          },
          {
            "Name": "Tank Top",
            "Children": []
          },
          {
            "Name": "Poncho",
            "Children": [
              {
                "Name": "Polo Neck Poncho",
                "Children": []
              }
            ]
          },
          {
            "Name": "Bolero",
            "Children": []
          },
          {
            "Name": "T-Shirt",
            "Children": [
              {
                "Name": "Cropped T-Shirt",
                "Children": []
              },
              {
                "Name": "Oversized T-Shirt",
                "Children": []
              },
              {
                "Name": "Regular Fit T-Shirt",
                "Children": []
              },
              {
                "Name": "Slim Fit T-Shirt",
                "Children": []
              },
              {
                "Name": "Long Fit T-Shirt",
                "Children": []
              },
              {
                "Name": "Relaxed Fit T-Shirt",
                "Children": []
              },
              {
                "Name": "Long Sleeved T-Shirt",
                "Children": []
              }
            ]
          },
          {
            "Name": "Jersey Shirt",
            "Children": [
              {
                "Name": "Regular Fit Jersey Shirt",
                "Children": []
              },
              {
                "Name": "Relaxed Fit Jersey Shirt",
                "Children": []
              },
              {
                "Name": "Jersey Polo Shirt",
                "Children": []
              },
              {
                "Name": "Polo Neck Jersey Shirt",
                "Children": [
                  {
                    "Name": "Slim Fit Polo Neck Jersey Shirt",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Vest",
            "Children": [
              {
                "Name": "Regular Fit Vest",
                "Children": []
              }
            ]
          },
          {
            "Name": "Henley Shirt",
            "Children": []
          },
          {
            "Name": "Shirt",
            "Children": [
              {
                "Name": "Polo Shirt",
                "Children": [
                  {
                    "Name": "Regular Fit Polo Shirt",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Relaxed Fit Shirt",
                "Children": []
              },
              {
                "Name": "Regular Fit Shirt",
                "Children": []
              },
              {
                "Name": "Muscle Fit Shirt",
                "Children": []
              },
              {
                "Name": "Slim Fit Shirt",
                "Children": []
              },
              {
                "Name": "Short Sleeved Shirt",
                "Children": []
              },
              {
                "Name": "Oversized Shirt",
                "Children": []
              },
              {
                "Name": "Long Shirt",
                "Children": []
              },
              {
                "Name": "Cropped Shirt",
                "Children": []
              },
              {
                "Name": "Stretch Shirt",
                "Children": []
              },
              {
                "Name": "Drawstring Shirt",
                "Children": []
              },
              {
                "Name": "Sleeveless Shirt",
                "Children": []
              }
            ]
          },
          {
            "Name": "Blouse",
            "Children": [
              {
                "Name": "One Shoulder Blouse",
                "Children": []
              },
              {
                "Name": "V-Neck Blouse",
                "Children": []
              },
              {
                "Name": "Boat Necked Blouse",
                "Children": []
              },
              {
                "Name": "Defined Shoulder Blouse",
                "Children": []
              },
              {
                "Name": "Pleated Blouse",
                "Children": []
              },
              {
                "Name": "Full Collared Blouse",
                "Children": []
              },
              {
                "Name": "Flounce Trimmed Blouse",
                "Children": []
              },
              {
                "Name": "Smocked Blouse",
                "Children": []
              },
              {
                "Name": "Puff Sleeved Blouse",
                "Children": []
              },
              {
                "Name": "Open Backed Blouse",
                "Children": []
              },
              {
                "Name": "Balloon Sleeved Blouse",
                "Children": []
              },
              {
                "Name": "Sleeveless Blouse",
                "Children": []
              },
              {
                "Name": "Cropped Blouse",
                "Children": []
              }
            ]
          }
        ]
      },
      {
        "Name": "Full Body Clothing",
        "Children": [
          {
            "Name": "Bathrobe",
            "Children": []
          },
          {
            "Name": "Dress",
            "Children": [
              {
                "Name": "Knee Length Dress",
                "Children": []
              },
              {
                "Name": "Mid Length Dress",
                "Children": []
              },
              {
                "Name": "Hooded Dress",
                "Children": []
              },
              {
                "Name": "V-Neck Dress",
                "Children": []
              },
              {
                "Name": "Boat Neck Dress",
                "Children": []
              },
              {
                "Name": "Polo Neck Dress",
                "Children": []
              },
              {
                "Name": "Maxi Dress",
                "Children": []
              },
              {
                "Name": "Balloon Sleeved Dress",
                "Children": []
              },
              {
                "Name": "Trumpet Sleeved Dress",
                "Children": []
              },
              {
                "Name": "Kaftan Dress",
                "Children": []
              },
              {
                "Name": "Puff Sleeved Dress",
                "Children": []
              },
              {
                "Name": "Pleated Dress",
                "Children": []
              },
              {
                "Name": "Draped Dress",
                "Children": []
              },
              {
                "Name": "Nursing Dress",
                "Children": []
              },
              {
                "Name": "Calf Length Dress",
                "Children": []
              },
              {
                "Name": "Open-Backed Dress",
                "Children": []
              },
              {
                "Name": "Sweater Vest Dress",
                "Children": []
              },
              {
                "Name": "Cut-Out Dress",
                "Children": []
              },
              {
                "Name": "Defined Shoulder Dress",
                "Children": []
              },
              {
                "Name": "Butterfly Sleeved Dress",
                "Children": []
              },
              {
                "Name": "Oversized Dress",
                "Children": []
              },
              {
                "Name": "Short Dress",
                "Children": []
              },
              {
                "Name": "Long Dress",
                "Children": []
              },
              {
                "Name": "Shirt Dress",
                "Children": [
                  {
                    "Name": "Oversized Shirt Dress",
                    "Children": []
                  },
                  {
                    "Name": "Tie Belt Shirt Dress",
                    "Children": []
                  },
                  {
                    "Name": "Draped Shirt Dress",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Collared Dress",
                "Children": [
                  {
                    "Name": "Flounce Collared Dress",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Skirt Dress",
                "Children": [
                  {
                    "Name": "Balloon Skirt Dress",
                    "Children": []
                  },
                  {
                    "Name": "Pleated Skirt Dress",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "A-Line Dress",
                "Children": [
                  {
                    "Name": "Ankle Length A-Line Dress",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Full Body Nightwear",
            "Children": [
              {
                "Name": "Nightdress",
                "Children": [
                  {
                    "Name": "Nightslip",
                    "Children": []
                  },
                  {
                    "Name": "Dressing Gown",
                    "Children": []
                  },
                  {
                    "Name": "Short Nightdress",
                    "Children": []
                  }
                ]
              },
              {
                "Name": "Pyjama Set",
                "Children": [
                  {
                    "Name": "Short Pyjama Set",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Dungarees",
            "Children": []
          },
          {
            "Name": "Swimsuit",
            "Children": []
          },
          {
            "Name": "Full Body Underwear",
            "Children": []
          },
          {
            "Name": "Bikini Set",
            "Children": []
          },
          {
            "Name": "Sports Set",
            "Children": []
          }
        ]
      },
      {
        "Name": "Shoes",
        "Children": [
          {
            "Name": "Boots",
            "Children": []
          },
          {
            "Name": "Derby Shoes",
            "Children": []
          },
          {
            "Name": "Trainers",
            "Children": []
          },
          {
            "Name": "Slippers",
            "Children": []
          },
          {
            "Name": "Loafers",
            "Children": []
          },
          {
            "Name": "Brogues",
            "Children": []
          },
          {
            "Name": "Mules",
            "Children": []
          },
          {
            "Name": "Blucher Shoes",
            "Children": []
          },
          {
            "Name": "Pumps",
            "Children": []
          },
          {
            "Name": "Slides",
            "Children": []
          },
          {
            "Name": "Sandals",
            "Children": []
          },
          {
            "Name": "Court Shoes",
            "Children": []
          },
          {
            "Name": "Sneakers",
            "Children": []
          }
        ]
      },
      {
        "Name": "Socks",
        "Children": [
          {
            "Name": "Sports Socks",
            "Children": []
          },
          {
            "Name": "Trainer Socks",
            "Children": []
          },
          {
            "Name": "Liner Socks",
            "Children": []
          },
          {
            "Name": "Knee Socks",
            "Children": []
          },
          {
            "Name": "Yoga Socks",
            "Children": []
          },
          {
            "Name": "Knee High Socks",
            "Children": []
          },
          {
            "Name": "Mini Socks",
            "Children": []
          }
        ]
      },
      {
        "Name": "Slipper Socks",
        "Children": []
      },
      {
        "Name": "Undergarments",
        "Children": [
          {
            "Name": "Bra",
            "Children": [
              {
                "Name": "Yoga Bra",
                "Children": []
              },
              {
                "Name": "Sports Bra",
                "Children": [
                  {
                    "Name": "Layered Sports Bra",
                    "Children": []
                  }
                ]
              }
            ]
          },
          {
            "Name": "Trunks",
            "Children": [
              {
                "Name": "Short Trunks",
                "Children": []
              },
              {
                "Name": "Mid Trunks",
                "Children": []
              }
            ]
          },
          {
            "Name": "Boxer Shorts",
            "Children": []
          },
          {
            "Name": "Briefs",
            "Children": []
          },
          {
            "Name": "Thermal Longjohns",
            "Children": []
          },
          {
            "Name": "Bralette",
            "Children": []
          },
          {
            "Name": "Undergarments Set",
            "Children": [
              {
                "Name": "Sports Bra and Shorts",
                "Children": []
              }
            ]
          }
        ]
      },
      {
        "Name": "Accessories",
        "Children": [
          {
            "Name": "Tie",
            "Children": []
          },
          {
            "Name": "Bow Tie",
            "Children": []
          },
          {
            "Name": "Cap",
            "Children": []
          },
          {
            "Name": "Tote Bag",
            "Children": []
          },
          {
            "Name": "Hat",
            "Children": []
          },
          {
            "Name": "Wrist Bands",
            "Children": []
          }
        ]
      }
    ]
  }
] as Array<Category>;
