import ProductDetail from "@/types/ProductDetail";
import ProductMaterialsToSend, {
  ComponentToSend,
  ExtractedMaterialToSend,
} from "@/types/ProductMaterialsToSend";
import SequenceItem from "@/types/SequenceItem";
import SequenceItemMaterials from "@/types/SequenceItemMaterials";
import ApiService from "./ApiService";
import LocalStorageService from "./LocalStorageService";

class BackendService {
  private readonly _apiService: ApiService;
  private readonly _localStorageService: LocalStorageService;

  constructor(
    apiService: ApiService,
    localStorageService: LocalStorageService
  ) {
    this._apiService = apiService;
    this._localStorageService = localStorageService;
  }

  public async get(): Promise<SequenceItem> {
    const query = {};
    const response: Response = await this._apiService.request(
      "POST",
      `/producttask`,
      query
    );
    return response.body as unknown as SequenceItem;
  }

  public async getExtract(url: string): Promise<Array<ProductDetail>> {
    const response: Response = await this._apiService.request(
      "GET",
      `/jsonld/extract`,
      { url: new URL(url) }
    );
    return (
      response.body as unknown as { ProductDetails: Array<ProductDetail> }
    ).ProductDetails;
  }

  public async update(data: {
    TaskId: string;
    Id: string;
    Taxonomy: Array<string> | undefined;
    Failed: boolean | undefined | null;
  }): Promise<void> {
    await this._apiService.request(
      "POST",
      `/producttask/${data.TaskId}`,
      undefined,
      false,
      {
        body: {
          Id: data.Id,
          Taxonomy: data.Taxonomy,
          Failed: data.Failed,
        },
      },
      true
    );
  }

  public async submit(
    url: string | null,
    description: Array<string>,
    title: string | null,
    classTaxonomy: Array<string>,
    siteTaxonomy: Array<string>
  ): Promise<void> {
    await this._apiService.request(
      "POST",
      `/producttask/submit`,
      undefined,
      false,
      {
        body: {
          TaskResult: {
            Taxonomy: classTaxonomy, // class
            Failed: false,
            Author: "",
          },
          Product: {
            SiteTaxonomy: siteTaxonomy,
            Url: url,
            ProductDescription: description,
            Title: title,
          },
        },
      },
      true
    );
  }

  public async addMaterials(product: SequenceItemMaterials): Promise<void> {
    const body = {
      Url: product.Url,
      Title: product.Title,
      ProductDescription: {
        Html: product.ProductDescription,
      },
      ComponentUnclear: product.ComponentUnclear,
      Components: [] as Array<ComponentToSend>,
    } as ProductMaterialsToSend;

    for (let i = 0; i < product.Components.length; i++) {
      const value = {
        Component: product.Components[i].name,
        ExtractedMaterials: [] as Array<ExtractedMaterialToSend>,
      };
      product.Components[i].materials.forEach((material) => {
        value.ExtractedMaterials.push({
          Material: material.name,
          FunctionalUnit: {
            Values: [
              {
                Unit: material.unit || "fraction",
                Value: material.value,
              },
            ],
          },
        });
      });
      body.Components.push(value);
    }

    await this._apiService.request(
      "POST",
      `/product/components`,
      undefined,
      false,
      {
        body,
      },
      true
    );
  }
}

export default BackendService;
