import SequenceItem from "@/types/SequenceItem";
import StorageKey from "@/types/StorageKey";
import ApiService from "./ApiService";
import BackendService from "./BackendService";
import LocalStorageService from "./LocalStorageService";

class SequenceService {
  private readonly _products: Array<SequenceItem>;
  private readonly _backendService: BackendService;
  private readonly _localStorageService: LocalStorageService;

  private _index: number;

  constructor(
    backendService: BackendService,
    localStorageService: LocalStorageService
  ) {
    this._backendService = backendService;
    this._localStorageService = localStorageService;
    const productsString = this._localStorageService.get(StorageKey.PRODUCTS);
    if (productsString && productsString.length > 0)
      this._products = JSON.parse(productsString);
    else this._products = [];
    this._index = this._products.length - 1;
  }

  async next(): Promise<SequenceItem> {
    if (this._index + 1 > this._products.length - 1) {
      this._products.push(await this._backendService.get());
      if (this._products.length > 5) this._products.splice(0, 1);
      this.save();
      this._index = this._products.length - 1;
    } else this._index = this._index + 1;
    return this._products[this._index];
  }

  previous(): SequenceItem {
    if (this._index - 1 > -1) {
      this._index = this._index - 1;
    }
    return this._products[this._index];
  }

  async update(sequenceItem: SequenceItem): Promise<void> {
    await this._backendService.update({
      TaskId: sequenceItem.Task.Id,
      Id: sequenceItem.Product.Id,
      Taxonomy: sequenceItem.Task.Taxonomy,
      Failed: sequenceItem.Task.Failed,
    });
    this._products[this._index] = sequenceItem;
    this.save();
  }
  save(): void {
    this._localStorageService.set(
      StorageKey.PRODUCTS,
      JSON.stringify(this._products)
    );
  }
}

export default new SequenceService(
  new BackendService(
    new ApiService(new LocalStorageService()),
    new LocalStorageService()
  ),
  new LocalStorageService()
);
