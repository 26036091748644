import Category from "./Category";

export default [
  {
    "Name": "Accessories",
    "Children": [
      {
        "Name": "Bag",
        "Children": [
          {
            "Name": "Tote Bag",
            "Children": null
          }
        ]
      },
      {
        "Name": "Hat",
        "Children": [
          {
            "Name": "Cap",
            "Children": null
          }
        ]
      },
      {
        "Name": "Tie",
        "Children": [
          {
            "Name": "Bow Tie",
            "Children": null
          }
        ]
      },
      {
        "Name": "Wrist Bands",
        "Children": null
      }
    ]
  },
  {
    "Name": "Footwear",
    "Children": [
      {
        "Name": "Boots",
        "Children": [
          {
            "Name": "Knee-high boots",
            "Children": null
          }
        ]
      },
      {
        "Name": "Brogues",
        "Children": null
      },
      {
        "Name": "Court Shoes",
        "Children": null
      },
      {
        "Name": "Sandals",
        "Children": [
          {
            "Name": "Slides",
            "Children": null
          }
        ]
      },
      {
        "Name": "Shoes",
        "Children": [
          {
            "Name": "Blucher Shoes",
            "Children": null
          },
          {
            "Name": "Derby Shoes",
            "Children": null
          },
          {
            "Name": "Loafers",
            "Children": null
          },
          {
            "Name": "Mules",
            "Children": null
          },
          {
            "Name": "Pumps",
            "Children": null
          }
        ]
      },
      {
        "Name": "Slippers",
        "Children": [
          {
            "Name": "Fake fur slippers",
            "Children": null
          }
        ]
      },
      {
        "Name": "Sneakers/Trainers",
        "Children": [
          {
            "Name": "Fashion trainers",
            "Children": null
          },
          {
            "Name": "Sports trainers",
            "Children": [
              {
                "Name": "Running shoes",
                "Children": null
              },
              {
                "Name": "Trail running shoes",
                "Children": null
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "Name": "Garment",
    "Children": [
      {
        "Name": "Bottoms",
        "Children": [
          {
            "Name": "Leggings",
            "Children": [
              {
                "Name": "Leggings",
                "Children": [
                  {
                    "Name": "Crop Leggings",
                    "Children": null
                  },
                  {
                    "Name": "Flared Leggings",
                    "Children": [
                      {
                        "Name": "High Waist Flared Leggings",
                        "Children": null
                      }
                    ]
                  },
                  {
                    "Name": "High Waist Jeggings",
                    "Children": null
                  },
                  {
                    "Name": "High Waist Leggings",
                    "Children": null
                  },
                  {
                    "Name": "Jeggings",
                    "Children": null
                  },
                  {
                    "Name": "Leather Leggings",
                    "Children": null
                  },
                  {
                    "Name": "Maternity Leggings",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Sports  Leggings",
                "Children": [
                  {
                    "Name": "High Waist Sports Leggings",
                    "Children": null
                  },
                  {
                    "Name": "Sports leggings - mesh",
                    "Children": null
                  }
                ]
              }
            ]
          },
          {
            "Name": "Shorts",
            "Children": [
              {
                "Name": "Cargo Shorts",
                "Children": null
              },
              {
                "Name": "Chino Shorts",
                "Children": null
              },
              {
                "Name": "Denim Shorts",
                "Children": null
              },
              {
                "Name": "High Waist Shorts",
                "Children": null
              },
              {
                "Name": "Knee Length Shorts",
                "Children": null
              },
              {
                "Name": "Leather Shorts",
                "Children": null
              },
              {
                "Name": "Legging Shorts",
                "Children": null
              },
              {
                "Name": "Maternity Shorts",
                "Children": null
              },
              {
                "Name": "Regular Fit Shorts",
                "Children": null
              },
              {
                "Name": "Relaxed Fit Shorts",
                "Children": null
              },
              {
                "Name": "Skirt Shorts",
                "Children": null
              },
              {
                "Name": "Slim Fit Shorts",
                "Children": null
              },
              {
                "Name": "Straight Fit Shorts",
                "Children": null
              },
              {
                "Name": "Technical Shorts",
                "Children": [
                  {
                    "Name": "Bike Shorts",
                    "Children": null
                  },
                  {
                    "Name": "Cycling Shorts",
                    "Children": null
                  },
                  {
                    "Name": "Mesh Shorts",
                    "Children": null
                  },
                  {
                    "Name": "Running Shorts",
                    "Children": null
                  }
                ]
              }
            ]
          },
          {
            "Name": "Skirt",
            "Children": [
              {
                "Name": "A-Line Skirt",
                "Children": [
                  {
                    "Name": "Ankle Length A-Line Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Calf Length A-Line Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Knee Length A-Line Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Short A-Line Skirt",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Ankle Length Skirt",
                "Children": null
              },
              {
                "Name": "Balloon Skirt",
                "Children": null
              },
              {
                "Name": "Bell Shaped Skirt",
                "Children": null
              },
              {
                "Name": "Calf Length Skirt",
                "Children": null
              },
              {
                "Name": "Draped Skirt",
                "Children": [
                  {
                    "Name": "Ankle Length Draped Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Calf Length Draped Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Knee Length Draped Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Short Draped Skirt",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "High Waist Skirt",
                "Children": null
              },
              {
                "Name": "Knee Length Skirt",
                "Children": null
              },
              {
                "Name": "Pencil Skirt",
                "Children": [
                  {
                    "Name": "Ankle length Pencil Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Calf Length Pencil Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Knee Length Pencil Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Short Pencil Skirt",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Permanent Pleated Skirt",
                "Children": null
              },
              {
                "Name": "Pleated Skirt",
                "Children": [
                  {
                    "Name": "Ankle Length Pleated Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Calf Length Pleated Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Knee Length Pleated Skirt",
                    "Children": null
                  },
                  {
                    "Name": "Short Pleated Skirt",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Short Skirt",
                "Children": null
              },
              {
                "Name": "Sports Skirt",
                "Children": [
                  {
                    "Name": "Tennis Skirt",
                    "Children": null
                  }
                ]
              }
            ]
          },
          {
            "Name": "Trousers",
            "Children": [
              {
                "Name": "Balloon Pants",
                "Children": null
              },
              {
                "Name": "Bootcut Pants",
                "Children": null
              },
              {
                "Name": "Cargo Pants",
                "Children": [
                  {
                    "Name": "Relaxed Fit Cargo Pants",
                    "Children": null
                  },
                  {
                    "Name": "Skinny Fit Cargo Pants",
                    "Children": null
                  },
                  {
                    "Name": "Slim Fit Cargo Pants",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Chinos",
                "Children": [
                  {
                    "Name": "Regular Fit Chinos",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Cigarette Pants",
                "Children": null
              },
              {
                "Name": "Corduroy Pants",
                "Children": [
                  {
                    "Name": "Slim Fit Corduroy Pants",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Cropped Pants",
                "Children": [
                  {
                    "Name": "Skinny Fit Cropped Pants",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Culottes",
                "Children": null
              },
              {
                "Name": "Flared Pants",
                "Children": null
              },
              {
                "Name": "High Waist Pants",
                "Children": null
              },
              {
                "Name": "Jeans",
                "Children": [
                  {
                    "Name": "High Waist Jeans",
                    "Children": null
                  },
                  {
                    "Name": "Loose Fit Jeans",
                    "Children": null
                  },
                  {
                    "Name": "Regular Jeans",
                    "Children": null
                  },
                  {
                    "Name": "Relaxed Jeans",
                    "Children": [
                      {
                        "Name": "High Waist Relaxed Jeans",
                        "Children": null
                      }
                    ]
                  },
                  {
                    "Name": "Skinny Jeans",
                    "Children": [
                      {
                        "Name": "Stretch Skinny Jeans",
                        "Children": null
                      }
                    ]
                  },
                  {
                    "Name": "Slim Jeans",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Joggers",
                "Children": [
                  {
                    "Name": "Cropped Joggers",
                    "Children": null
                  },
                  {
                    "Name": "High Waist Joggers",
                    "Children": null
                  },
                  {
                    "Name": "Loose Fit Joggers",
                    "Children": [
                      {
                        "Name": "High Waist Loose Fit Joggers",
                        "Children": null
                      }
                    ]
                  },
                  {
                    "Name": "Regular Fit Joggers",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Knitted Pants",
                "Children": null
              },
              {
                "Name": "Leather Pants",
                "Children": null
              },
              {
                "Name": "Pyjama Bottoms",
                "Children": [
                  {
                    "Name": "Pyjama Shorts",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Regular Fit Pants",
                "Children": null
              },
              {
                "Name": "Skinny Fit Pants",
                "Children": null
              },
              {
                "Name": "Slim Fit Pants",
                "Children": null
              },
              {
                "Name": "Suit Pants",
                "Children": [
                  {
                    "Name": "Cropped Suit Pants",
                    "Children": null
                  },
                  {
                    "Name": "Regular Fit Suit Pants",
                    "Children": null
                  },
                  {
                    "Name": "Skinny Fit Suit Pants",
                    "Children": null
                  },
                  {
                    "Name": "Slim Fit Suit Pants",
                    "Children": null
                  },
                  {
                    "Name": "Straight Fit Suit Pants",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Suit Pants - pleated",
                "Children": null
              },
              {
                "Name": "Technical Pants",
                "Children": [
                  {
                    "Name": "Hiking trousers",
                    "Children": null
                  },
                  {
                    "Name": "Hiking trousers zip off shorts",
                    "Children": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "Name": "Dress",
        "Children": [
          {
            "Name": "Bathrobe",
            "Children": null
          },
          {
            "Name": "Dress",
            "Children": [
              {
                "Name": "A-Line Dress",
                "Children": [
                  {
                    "Name": "Ankle Length A-Line Dress",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Balloon Skirt Dress",
                "Children": null
              },
              {
                "Name": "Balloon Sleeved Dress",
                "Children": null
              },
              {
                "Name": "Boat Neck Dress",
                "Children": null
              },
              {
                "Name": "Butterfly Sleeved Dress",
                "Children": null
              },
              {
                "Name": "Calf Length Dress",
                "Children": null
              },
              {
                "Name": "Collared Dress",
                "Children": [
                  {
                    "Name": "Flounce Collared Dress",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Cut-Out Dress",
                "Children": null
              },
              {
                "Name": "Defined Shoulder Dress",
                "Children": null
              },
              {
                "Name": "Draped Dress",
                "Children": null
              },
              {
                "Name": "Hooded Dress",
                "Children": null
              },
              {
                "Name": "Kaftan Dress",
                "Children": null
              },
              {
                "Name": "Knee Length Dress",
                "Children": null
              },
              {
                "Name": "Knitted dress",
                "Children": null
              },
              {
                "Name": "Long Dress",
                "Children": null
              },
              {
                "Name": "Maxi Dress",
                "Children": null
              },
              {
                "Name": "Mid Length Dress",
                "Children": null
              },
              {
                "Name": "Nursing Dress",
                "Children": null
              },
              {
                "Name": "Open-Backed Dress",
                "Children": null
              },
              {
                "Name": "Oversized Dress",
                "Children": null
              },
              {
                "Name": "Pleated Dress",
                "Children": null
              },
              {
                "Name": "Pleated Skirt Dress",
                "Children": null
              },
              {
                "Name": "Polo Neck Dress",
                "Children": null
              },
              {
                "Name": "Puff Sleeved Dress",
                "Children": null
              },
              {
                "Name": "Shirt Dress",
                "Children": [
                  {
                    "Name": "Draped Shirt Dress",
                    "Children": null
                  },
                  {
                    "Name": "Oversized Shirt Dress",
                    "Children": null
                  },
                  {
                    "Name": "Tie Belt Shirt Dress",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Short Dress",
                "Children": null
              },
              {
                "Name": "Sweater Vest Dress",
                "Children": null
              },
              {
                "Name": "Trumpet Sleeved Dress",
                "Children": null
              },
              {
                "Name": "V-Neck Dress",
                "Children": null
              }
            ]
          },
          {
            "Name": "Nightdress",
            "Children": [
              {
                "Name": "Nightdress",
                "Children": [
                  {
                    "Name": "Dressing Gown",
                    "Children": null
                  },
                  {
                    "Name": "Nightslip",
                    "Children": null
                  },
                  {
                    "Name": "Short Nightdress",
                    "Children": null
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "Name": "Coat/Jacket",
        "Children": [
          {
            "Name": "Blazer/Suit Jacket",
            "Children": [
              {
                "Name": "Cropped Blazer",
                "Children": null
              },
              {
                "Name": "Double Breasted Blazer",
                "Children": null
              },
              {
                "Name": "Single Breasted Blazer",
                "Children": null
              },
              {
                "Name": "Slim Fit Blazer",
                "Children": null
              },
              {
                "Name": "Tuxedo jacket",
                "Children": [
                  {
                    "Name": "Slim Fit Tuxedo Jacket",
                    "Children": null
                  }
                ]
              }
            ]
          },
          {
            "Name": "Bolero",
            "Children": null
          },
          {
            "Name": "Cape",
            "Children": null
          },
          {
            "Name": "Coat",
            "Children": [
              {
                "Name": "Down Coat",
                "Children": null
              },
              {
                "Name": "Trenchcoat",
                "Children": [
                  {
                    "Name": "Oversized Trenchcoat",
                    "Children": null
                  }
                ]
              }
            ]
          },
          {
            "Name": "Jacket",
            "Children": [
              {
                "Name": "Baseball Jacket",
                "Children": null
              },
              {
                "Name": "Biker Jacket",
                "Children": null
              },
              {
                "Name": "Boxy Style Jacket",
                "Children": null
              },
              {
                "Name": "Cape Jacket",
                "Children": null
              },
              {
                "Name": "Casual Jacket",
                "Children": null
              },
              {
                "Name": "Cropped Jacket",
                "Children": null
              },
              {
                "Name": "Double Breasted Jacket",
                "Children": null
              },
              {
                "Name": "Gilet",
                "Children": [
                  {
                    "Name": "Cropped Gilet",
                    "Children": null
                  },
                  {
                    "Name": "Hooded Gilet",
                    "Children": [
                      {
                        "Name": "Hooded Puffer Gilet",
                        "Children": null
                      }
                    ]
                  },
                  {
                    "Name": "Oversized Gilet",
                    "Children": null
                  },
                  {
                    "Name": "Varsity Gilet",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Hooded Jacket",
                "Children": null
              },
              {
                "Name": "Oversized Jacket",
                "Children": null
              },
              {
                "Name": "Parka",
                "Children": [
                  {
                    "Name": "Loose Fit Parka",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Popover Jacket",
                "Children": null
              },
              {
                "Name": "Regular Fit Jacket",
                "Children": null
              },
              {
                "Name": "Relaxed Fit Jacket",
                "Children": null
              },
              {
                "Name": "Shell Jacket",
                "Children": null
              },
              {
                "Name": "Single Breasted Jacket",
                "Children": null
              },
              {
                "Name": "Skinny Fit Jacket",
                "Children": null
              },
              {
                "Name": "Sleeveless Jacket",
                "Children": null
              },
              {
                "Name": "Slim Fit Jacket",
                "Children": null
              },
              {
                "Name": "Sports/Technical/Outdoor Jacket",
                "Children": [
                  {
                    "Name": "Track Jacket",
                    "Children": null
                  },
                  {
                    "Name": "Waterproof and down outdoor jacket",
                    "Children": null
                  },
                  {
                    "Name": "Waterproof outdoor jacket",
                    "Children": null
                  },
                  {
                    "Name": "Windbreaker jacket",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Varsity Jacket",
                "Children": [
                  {
                    "Name": "Oversized Varsity Jacket",
                    "Children": null
                  }
                ]
              }
            ]
          },
          {
            "Name": "Poncho",
            "Children": [
              {
                "Name": "Polo Neck Poncho",
                "Children": null
              },
              {
                "Name": "Waterproof poncho",
                "Children": null
              }
            ]
          },
          {
            "Name": "Tailored Coat",
            "Children": [
              {
                "Name": "Calf Length Coat",
                "Children": null
              },
              {
                "Name": "Collared Coat",
                "Children": [
                  {
                    "Name": "High Collared Coat",
                    "Children": [
                      {
                        "Name": "Single Breasted High Collared Coat",
                        "Children": null
                      }
                    ]
                  }
                ]
              },
              {
                "Name": "Double Breasted Coat",
                "Children": null
              },
              {
                "Name": "Down Coat",
                "Children": null
              },
              {
                "Name": "Knee Length Coat",
                "Children": null
              },
              {
                "Name": "Long Coat",
                "Children": null
              },
              {
                "Name": "Oversized Coat",
                "Children": null
              },
              {
                "Name": "Single Breasted Coat",
                "Children": null
              }
            ]
          },
          {
            "Name": "Waistcoat",
            "Children": [
              {
                "Name": "Biker Waistcoat",
                "Children": null
              },
              {
                "Name": "Slim Fit Waistcoat",
                "Children": null
              }
            ]
          },
          {
            "Name": "Waterproof Coat",
            "Children": [
              {
                "Name": "Waterproof double breasted coat",
                "Children": null
              },
              {
                "Name": "Waterproof Down Coat",
                "Children": null
              },
              {
                "Name": "Waterproof single breasted coat",
                "Children": null
              },
              {
                "Name": "Waterproof zip up coat",
                "Children": null
              }
            ]
          }
        ]
      },
      {
        "Name": "Jumpsuits",
        "Children": [
          {
            "Name": "Boiler Suit",
            "Children": null
          },
          {
            "Name": "Dungarees",
            "Children": null
          },
          {
            "Name": "Jumpsuit",
            "Children": [
              {
                "Name": "Halterneck Jumpsuit",
                "Children": null
              },
              {
                "Name": "Hooded Jumpsuit",
                "Children": null
              }
            ]
          },
          {
            "Name": "Playsuit",
            "Children": [
              {
                "Name": "Drawstring Playsuit",
                "Children": null
              },
              {
                "Name": "Polo Neck Playsuit",
                "Children": null
              }
            ]
          }
        ]
      },
      {
        "Name": "Top",
        "Children": [
          {
            "Name": "Cardigan",
            "Children": [
              {
                "Name": "Boxy Style Cardigan",
                "Children": null
              },
              {
                "Name": "Cropped Cardigan",
                "Children": null
              },
              {
                "Name": "Double Breasted Cardigan",
                "Children": null
              },
              {
                "Name": "Hooded Cardigan",
                "Children": [
                  {
                    "Name": "Long Hooded Cardigan",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Long Cardigan",
                "Children": null
              },
              {
                "Name": "Shawl Collar Cardigan",
                "Children": null
              },
              {
                "Name": "Short Cardigan",
                "Children": null
              },
              {
                "Name": "Tie Belt Cardigan",
                "Children": null
              }
            ]
          },
          {
            "Name": "Crop Top",
            "Children": null
          },
          {
            "Name": "Henley Shirt",
            "Children": null
          },
          {
            "Name": "Hooded Sweatshirt",
            "Children": [
              {
                "Name": "Cropped Hoodie",
                "Children": null
              },
              {
                "Name": "Oversized Hoodie",
                "Children": null
              },
              {
                "Name": "Regular Fit Hoodie",
                "Children": null
              },
              {
                "Name": "Relaxed Fit Hoodie",
                "Children": null
              },
              {
                "Name": "Sports Hoodie",
                "Children": [
                  {
                    "Name": "Cropped Sports Hoodie",
                    "Children": null
                  }
                ]
              }
            ]
          },
          {
            "Name": "Blouse",
            "Children": [
              {
                "Name": "Balloon Sleeved Blouse",
                "Children": null
              },
              {
                "Name": "Boat Necked Blouse",
                "Children": null
              },
              {
                "Name": "Cropped Blouse",
                "Children": null
              },
              {
                "Name": "Defined Shoulder Blouse",
                "Children": null
              },
              {
                "Name": "Flounce Trimmed Blouse",
                "Children": null
              },
              {
                "Name": "Full Collared Blouse",
                "Children": null
              },
              {
                "Name": "One Shoulder Blouse",
                "Children": null
              },
              {
                "Name": "Open Backed Blouse",
                "Children": null
              },
              {
                "Name": "Pleated Blouse",
                "Children": null
              },
              {
                "Name": "Puff Sleeved Blouse",
                "Children": null
              },
              {
                "Name": "Sleeveless Blouse",
                "Children": null
              },
              {
                "Name": "Smocked Blouse",
                "Children": null
              },
              {
                "Name": "V-Neck Blouse",
                "Children": null
              }
            ]
          },
          {
            "Name": "Jersey Shirt",
            "Children": [
              {
                "Name": "Jersey Polo Shirt",
                "Children": null
              },
              {
                "Name": "Polo Neck Jersey Shirt",
                "Children": [
                  {
                    "Name": "Slim Fit Polo Neck Jersey Shirt",
                    "Children": null
                  },
                  {
                    "Name": "Regular Fit Polo Neck Jersey Shirt",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Regular Fit Jersey Shirt",
                "Children": null
              },
              {
                "Name": "Relaxed Fit Jersey Shirt",
                "Children": null
              }
            ]
          },
          {
            "Name": "Knitted Jumper",
            "Children": [
              {
                "Name": "Boxy Style Jumper",
                "Children": null
              },
              {
                "Name": "Collared Jumper",
                "Children": [
                  {
                    "Name": "Stand Up Collar Jumper",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Cropped Jumper",
                "Children": null
              },
              {
                "Name": "Loose Fit Jumper",
                "Children": null
              },
              {
                "Name": "Muscle Fit Jumper",
                "Children": null
              },
              {
                "Name": "Off Shoulder Jumper",
                "Children": null
              },
              {
                "Name": "Oversized Jumper",
                "Children": null
              },
              {
                "Name": "Polo Neck Jumper",
                "Children": [
                  {
                    "Name": "Cropped Polo Neck Jumper",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Regular Fit Jumper",
                "Children": [
                  {
                    "Name": "Regular Fit Polo Neck Jumper",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Relaxed Fit Jumper",
                "Children": null
              },
              {
                "Name": "Round Neck Jumper",
                "Children": null
              },
              {
                "Name": "Sleeveless Jumper",
                "Children": null
              },
              {
                "Name": "Slim Fit Jumper",
                "Children": null
              },
              {
                "Name": "V-Neck Jumper",
                "Children": null
              },
              {
                "Name": "Sweater Vest",
                "Children": [
                  {
                    "Name": "Oversized Sweater Vest",
                    "Children": null
                  },
                  {
                    "Name": "Polo Neck Sweater Vest",
                    "Children": null
                  },
                  {
                    "Name": "Regular Fit Sweater Vest",
                    "Children": null
                  }
                ]
              }
            ]
          },
          {
            "Name": "Nightwear Top",
            "Children": [
              {
                "Name": "Nightshirt",
                "Children": null
              },
              {
                "Name": "Pyjama Shirt",
                "Children": null
              }
            ]
          },
          {
            "Name": "Shirt",
            "Children": [
              {
                "Name": "Cropped Shirt",
                "Children": null
              },
              {
                "Name": "Drawstring Shirt",
                "Children": null
              },
              {
                "Name": "Long Shirt",
                "Children": null
              },
              {
                "Name": "Oversized Shirt",
                "Children": null
              },
              {
                "Name": "Regular Fit Shirt",
                "Children": null
              },
              {
                "Name": "Relaxed Fit Shirt",
                "Children": null
              },
              {
                "Name": "Short Sleeved Shirt",
                "Children": null
              },
              {
                "Name": "Sleeveless Shirt",
                "Children": null
              },
              {
                "Name": "Slim Fit Shirt",
                "Children": null
              },
              {
                "Name": "Stretch Shirt",
                "Children": null
              }
            ]
          },
          {
            "Name": "Sweatshirt",
            "Children": [
              {
                "Name": "Oversized Sweatshirt",
                "Children": null
              },
              {
                "Name": "Regular Fit Sweatshirt",
                "Children": null
              },
              {
                "Name": "Relaxed Fit Sweatshirt",
                "Children": null
              }
            ]
          },
          {
            "Name": "T-Shirt",
            "Children": [
              {
                "Name": "Cropped T-Shirt",
                "Children": null
              },
              {
                "Name": "Long Fit T-Shirt",
                "Children": null
              },
              {
                "Name": "Long Sleeved T-Shirt",
                "Children": null
              },
              {
                "Name": "Oversized T-Shirt",
                "Children": null
              },
              {
                "Name": "Regular Fit T-Shirt",
                "Children": null
              },
              {
                "Name": "Relaxed Fit T-Shirt",
                "Children": null
              },
              {
                "Name": "Slim Fit T-Shirt",
                "Children": null
              },
              {
                "Name": "Muscle T-Shirt",
                "Children": null
              }
            ]
          },
          {
            "Name": "Tank Top",
            "Children": null
          },
          {
            "Name": "Technical Top",
            "Children": [
              {
                "Name": "Long Sleeved Sports Top",
                "Children": null
              },
              {
                "Name": "Regular Fit Sports Top",
                "Children": null
              },
              {
                "Name": "Relaxed Fit Sports Top",
                "Children": null
              }
            ]
          },
          {
            "Name": "Tunic",
            "Children": [
              {
                "Name": "Long Sleeved Tunic",
                "Children": null
              },
              {
                "Name": "Polo Neck Tunic",
                "Children": null
              }
            ]
          },
          {
            "Name": "Vest",
            "Children": [
              {
                "Name": "Regular Fit Vest",
                "Children": null
              }
            ]
          }
        ]
      },
      {
        "Name": "Swimwear/Underwear",
        "Children": [
          {
            "Name": "Bikini Bottom",
            "Children": [
              {
                "Name": "Sports Bikini Bottom",
                "Children": null
              },
              {
                "Name": "Thong Bikini Bottom",
                "Children": null
              },
              {
                "Name": "Boy leg Bikini Bottom",
                "Children": null
              },
              {
                "Name": "String Bikini Bottom",
                "Children": null
              },
              {
                "Name": "High leg Bikini Bottom",
                "Children": null
              },
              {
                "Name": "High waisted Bikini Bottom",
                "Children": null
              }
            ]
          },
          {
            "Name": "Bikini Set",
            "Children": null
          },
          {
            "Name": "Bikini Top",
            "Children": [
              {
                "Name": "Padded Bikini Top",
                "Children": null
              },
              {
                "Name": "Push-up Bikini Top",
                "Children": null
              },
              {
                "Name": "Sports Bikini Top",
                "Children": null
              },
              {
                "Name": "String Bikini Top",
                "Children": null
              },
              {
                "Name": "Halter Neck Bikini Top",
                "Children": null
              },
              {
                "Name": "Underwired Bikini Top",
                "Children": null
              },
              {
                "Name": "Tankini Bikini Top",
                "Children": null
              }
            ]
          },
          {
            "Name": "Boxer Shorts",
            "Children": null
          },
          {
            "Name": "Bra",
            "Children": [
              {
                "Name": "Sports Bra",
                "Children": [
                  {
                    "Name": "Layered Sports Bra",
                    "Children": null
                  }
                ]
              },
              {
                "Name": "Yoga Bra",
                "Children": null
              }
            ]
          },
          {
            "Name": "Bralette",
            "Children": null
          },
          {
            "Name": "Briefs",
            "Children": null
          },
          {
            "Name": "Tights",
            "Children": [
              {
                "Name": "Fishnet Tights",
                "Children": null
              }
            ]
          },
          {
            "Name": "Full Body Nightwear",
            "Children": null
          },
          {
            "Name": "Full Body Underwear",
            "Children": [
              {
                "Name": "Thong Body",
                "Children": null
              }
            ]
          },
          {
            "Name": "Socks",
            "Children": [
              {
                "Name": "Knee High Socks",
                "Children": null
              },
              {
                "Name": "Knee Socks",
                "Children": null
              },
              {
                "Name": "Liner Socks",
                "Children": null
              },
              {
                "Name": "Mini Socks",
                "Children": null
              },
              {
                "Name": "Slipper Socks",
                "Children": null
              },
              {
                "Name": "Sports Socks",
                "Children": null
              },
              {
                "Name": "Trainer Socks",
                "Children": null
              },
              {
                "Name": "Yoga Socks",
                "Children": null
              }
            ]
          },
          {
            "Name": "Swimsuit",
            "Children": [
              {
                "Name": "Halter neck swimsuit",
                "Children": null
              },
              {
                "Name": "Sports swimsuit",
                "Children": null
              },
              {
                "Name": "Underwired Swim suit",
                "Children": null
              }
            ]
          },
          {
            "Name": "Swimwear",
            "Children": [
              {
                "Name": "Swimming Briefs",
                "Children": null
              },
              {
                "Name": "Swimming Shorts",
                "Children": null
              },
              {
                "Name": "Short Bottom Swimwear",
                "Children": null
              }
            ]
          },
          {
            "Name": "Thermal Longjohns",
            "Children": null
          },
          {
            "Name": "Trunks",
            "Children": [
              {
                "Name": "Mid Trunks",
                "Children": null
              },
              {
                "Name": "Short Trunks",
                "Children": null
              }
            ]
          },
          {
            "Name": "Undergarments Set",
            "Children": [
              {
                "Name": "Lingerie Set",
                "Children": null
              },
              {
                "Name": "Sports Bra and Shorts",
                "Children": null
              }
            ]
          },
          {
            "Name": "Unitard",
            "Children": [
              {
                "Name": "Sleeveless Unitard",
                "Children": null
              }
            ]
          }
        ]
      }
    ]
  }
] as Array<Category>;
