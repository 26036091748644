

























































import MaterialComponentType from "@/types/MaterialComponentType";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  props: ["initValue", "hasCheckbox", "isEntireProduct"],
  setup(props, { emit }) {
    const { initValue, isEntireProduct } = props;

    const value = ref<MaterialComponentType>(initValue);
    const entireProduct = ref<boolean>(isEntireProduct);

    const handleNameChange = (event: InputEvent) => {
      const name = (event.target as HTMLInputElement).value;
      value.value.name = name;
      emit("change", value.value);
    };

    const onDelete = () => {
      emit("delete");
    };

    const onAddMaterial = () => {
      value.value.materials.push({
        name: "",
        value: 100,
        unit: "fraction",
      });
      emit("change", value.value);
    };

    const handleMaterialNameChange = (event: InputEvent, index: number) => {
      const name = (event.target as HTMLInputElement).value;
      value.value.materials[index].name = name;
      emit("change", value.value);
    };

    const handleMaterialNameValue = (event: number, index: number): void => {
      value.value.materials[index].value = !event ? 1 : event;
      emit("change", value.value);
    };

    const handleMaterialNameUnit = (event: string, index: number): void => {
      value.value.materials[index].unit = !event ? "fraction" : event;
      emit("change", value.value);
    };

    const onMaterialDelete = (index: number): void => {
      value.value.materials.splice(index, 1);
      emit("change", value.value);
    };

    const onEntireProduct = (event: InputEvent) => {
      entireProduct.value = (event.target as HTMLInputElement).checked;
      if (entireProduct.value) {
        value.value.name = "";
      }
      emit("entire", entireProduct.value);
    };

    return {
      value,
      entireProduct,
      handleNameChange,
      onDelete,
      onAddMaterial,
      handleMaterialNameChange,
      handleMaterialNameValue,
      onMaterialDelete,
      onEntireProduct,
      handleMaterialNameUnit,
    };
  },
});
