import SequenceItemNewTex from "@/types/SequenceItemNewTex";
import ApiService from "./ApiService";
import LocalStorageService from "./LocalStorageService";

///////////////

class BackendServiceNewTex {
  private readonly _apiService: ApiService;
  private readonly _localStorageService: LocalStorageService;

  constructor(
    apiService: ApiService,
    localStorageService: LocalStorageService
  ) {
    this._apiService = apiService;
    this._localStorageService = localStorageService;
  }

  public async get(): Promise<SequenceItemNewTex> {
    const query = {};
    const response: Response = await this._apiService.request(
      "POST",
      `/producttask`,
      query
    );
    return response.body as unknown as SequenceItemNewTex;
  }

  public async update(data: {
    TaskId: string;
    Id: string;
    Taxonomy: Array<string> | undefined;
    ProductDescription: Array<string>;
    Title: string;
    Failed: boolean | undefined | null;
    OutOfTaxonomy?: string;
    IsNonAdult?: boolean;
  }): Promise<void> {
    await this._apiService.request(
      "POST",
      `/producttask/${data.TaskId}`,
      undefined,
      false,
      {
        body: {
          Id: data.TaskId,
          Taxonomy: data.Taxonomy,
          Failed: data.Failed,
          Title: data.Title,
          ProductDescription: data.ProductDescription,
          OutOfTaxonomy: data.OutOfTaxonomy,
          IsNonAdult: data.IsNonAdult,
        },
      },
      true
    );
  }
}

export default BackendServiceNewTex;
