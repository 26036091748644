






















import { defineComponent, ref, watch } from "@vue/composition-api";
import TaxonomyService from "@/serives/TaxonomyService";
import Taxonomy from "@/types/Taxonomy";
import TaxonomyNew from "@/types/TaxonomyNew";
import Category from "@/types/Category";

export default defineComponent({
  props: ["defaultValue", "new"],
  setup(props, { emit }) {
    // TODO actual tree data
    const treeData = props.new
      ? TaxonomyNew.map((item: Category) => TaxonomyService.map(item, ""))
      : Taxonomy.map((item: Category) => TaxonomyService.map(item, ""));

    const value = ref<string | undefined>("");

    watch(
      () => props.defaultValue,
      (defaultValue: Array<string> | undefined) => {
        if (defaultValue) value.value = defaultValue.join("^");
      }
    );

    const onSelect = (input: string) => {
      emit("select", input.split("^"));
    };

    return {
      treeData,
      value,
      onSelect,
    };
  },
});
