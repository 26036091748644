import StorageKey from "@/types/StorageKey";
import LocalStorageService from "./LocalStorageService";

class ApiService {
  private readonly baseUrl: string;
  private readonly _localStorageService: LocalStorageService;

  constructor(localStorageService: LocalStorageService) {
    // this.baseUrl = process.env.VUE_APP_API_BASE_URL;
    this.baseUrl = "/-";
    this._localStorageService = localStorageService;
  }

  public async request(
    method: string,
    url: string,
    query: any = null,
    absoluteUrl = false,
    options: any = {},
    preventBody = false
  ): Promise<any> {
    if (!query) query = {};
    query.username = this._localStorageService.get(StorageKey.USERNAME);
    query.key = this._localStorageService.get(StorageKey.KEY);

    if ("body" in options && options.body instanceof Object) {
      options.body = JSON.stringify(options.body);
      options.headers = {
        ...options.headers,
        "Content-Type": "application/json",
      };
    } else
      options.headers = {
        ...options.headers,
      };

    const search = query ? `?${new URLSearchParams(query).toString()}` : "";
    let nurl = this.baseUrl ? `${this.baseUrl}${url}` : `/-${url}`; // second part is for integration with react;
    if (absoluteUrl) {
      nurl = url;
    }
    const response = await fetch(`${nurl}${search}`, {
      method,
      credentials: "include",
      ...options,
    });
    if (response.ok) {
      const noContent = response.status === 201 || response.status === 204;
      return {
        headers: response.headers,
        body: noContent || preventBody ? undefined : await response.json(),
      };
    } else {
      throw {
        response,
        body: await response.json(),
      };
    }
  }
}

export default ApiService;
