import Category from "@/types/Category";
import TreeData from "@/types/TreeData";

class TaxonomyService {
  map(category: Category, taxonomyTree: string): TreeData {
    let value = "";
    if (taxonomyTree && taxonomyTree.length > 0)
      value = [category.Name, taxonomyTree].join("^");
    else value = category.Name;
    return {
      title: category.Name,
      value: value,
      key: value,
      children: (category.Children||[]).map((item) => this.map(item, value)),
    };
  }
  findByName(category: Category, name: string): Category | undefined {
    if (category.Name === name) {
      return category;
    }
    for (let i = 0; i < (category.Children||[]).length; i++) {
      const foundLabel: Category | undefined = this.findByName(
        category.Children[i],
        name
      );
      if (foundLabel) {
        return foundLabel;
      }
    }
    return undefined;
  }
  isAcceptable(category: Category, name: string): boolean {
    const selectedCategory = this.findByName(category, name);
    if (!selectedCategory) return false;
    return selectedCategory.Children.length === 0;
  }
}

export default new TaxonomyService();
