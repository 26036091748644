import SequenceItemMaterials from "@/types/SequenceItemMaterials";
import StorageKey from "@/types/StorageKey";
import ApiService from "./ApiService";
import BackendService from "./BackendService";
import LocalStorageService from "./LocalStorageService";

class SequenceService {
  private readonly _products: Array<SequenceItemMaterials>;
  private readonly _backendService: BackendService;
  private readonly _localStorageService: LocalStorageService;

  private _index: number;

  constructor(
    backendService: BackendService,
    localStorageService: LocalStorageService
  ) {
    this._backendService = backendService;
    this._localStorageService = localStorageService;
    const productsString = this._localStorageService.get(
      StorageKey.PRODUCT_MATERIALS
    );
    if (productsString && productsString.length > 0)
      this._products = JSON.parse(productsString);
    else this._products = [];
    this._index = this._products.length;
  }

  async nextAndSave(
    product: SequenceItemMaterials
  ): Promise<SequenceItemMaterials | undefined> {
    await this._backendService.addMaterials(product);
    if (this._index > this._products.length - 1) {
      this._products.push(product);
      if (this._products.length > 5) this._products.splice(0, 1);
      this._index = this._products.length;
    } else {
      this._products[this._index] = product;
      this._index = this._index + 1;
    }
    this.save();
    return this._index > this._products.length - 1
      ? undefined
      : this._products[this._index];
  }

  previous(): SequenceItemMaterials {
    if (this._index - 1 > -1) {
      this._index = this._index - 1;
    }
    return this._products[this._index];
  }

  save() {
    this._localStorageService.set(
      StorageKey.PRODUCT_MATERIALS,
      JSON.stringify(this._products)
    );
  }
}

export default new SequenceService(
  new BackendService(
    new ApiService(new LocalStorageService()),
    new LocalStorageService()
  ),
  new LocalStorageService()
);
