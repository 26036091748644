import SequenceItemNewTex from "@/types/SequenceItemNewTex";
import StorageKey from "@/types/StorageKey";
import ApiService from "./ApiService";
import BackendServiceNewTex from "./BackendServiceNewTex";
import LocalStorageService from "./LocalStorageService";

class SequenceServiceNewTex {
  private readonly _products: Array<SequenceItemNewTex>;
  private readonly _backendService: BackendServiceNewTex;
  private readonly _localStorageService: LocalStorageService;

  private _index: number;

  constructor(
    backendService: BackendServiceNewTex,
    localStorageService: LocalStorageService
  ) {
    this._backendService = backendService;
    this._localStorageService = localStorageService;
    const productsString = this._localStorageService.get(
      StorageKey.PRODUCTS_NEW_TEX
    );
    if (productsString && productsString.length > 0)
      this._products = JSON.parse(productsString);
    else this._products = [];
    this._index = this._products.length - 1;
  }

  async next(): Promise<SequenceItemNewTex> {
    if (this._index + 1 > this._products.length - 1) {
      const data = await this._backendService.get();
      this._products.push({
        Product: {
          Url: data.Product.Url,
          Id: "",
          ProductDescription: [""],
          Title: "",
          SiteTaxonomy: [],
        },
        Task: data.Task,
      });
      if (this._products.length > 5) this._products.splice(0, 1);
      this.save();
      this._index = this._products.length - 1;
    } else this._index = this._index + 1;
    return this._products[this._index];
  }

  previous(): SequenceItemNewTex {
    if (this._index - 1 > -1) {
      this._index = this._index - 1;
    }
    return this._products[this._index];
  }

  async update(sequenceItem: SequenceItemNewTex): Promise<void> {
    await this._backendService.update({
      TaskId: sequenceItem.Task.Id,
      Id: sequenceItem.Product.Id,
      Taxonomy: sequenceItem.Task.Taxonomy,
      Failed: sequenceItem.Task.Failed,
      Title: sequenceItem.Product.Title,
      ProductDescription: sequenceItem.Product.ProductDescription,
      IsNonAdult: sequenceItem.Product.IsNonAdult,
      OutOfTaxonomy: sequenceItem.Product.OutOfTaxonomy,
    });
    this._products[this._index] = sequenceItem;
    this.save();
  }
  save(): void {
    this._localStorageService.set(
      StorageKey.PRODUCTS_NEW_TEX,
      JSON.stringify(this._products)
    );
  }
}

export default new SequenceServiceNewTex(
  new BackendServiceNewTex(
    new ApiService(new LocalStorageService()),
    new LocalStorageService()
  ),
  new LocalStorageService()
);
